var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "margin": 0,
      "title": _vm.title,
      "subtitle": _vm.subtitle
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('close');
            }
          }
        }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('v-row', [_c('v-col', [_c('v-list', _vm._l(_vm.pages, function (page) {
    return _c('v-list-item', {
      key: page.subtitle,
      on: {
        "click": function click($event) {
          return _vm.setNextStep(page.nextPage);
        }
      }
    }, [_c('v-list-item-avatar', [_c('v-icon', {
      attrs: {
        "color": "info"
      }
    }, [_vm._v(" " + _vm._s(page.icon) + " ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(page.title))]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(page.subtitle))])], 1), _c('v-list-item-action', [_vm._v(" " + _vm._s(page.buttonText) + " ")])], 1);
  }), 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }