var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "margin": 0,
      "title": _vm.$t('components.partner.PartnerReportInitializeCard.sendMail.title'),
      "subtitle": _vm.$t('components.partner.PartnerReportInitializeCard.description')
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('close');
            }
          }
        }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1)];
      },
      proxy: true
    }, {
      key: "card-actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.setNextStep(_vm.PagesEnum.CHOICE);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportInitializeCard.back")) + " ")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": "",
            "outlined": "",
            "color": "info"
          },
          on: {
            "click": _vm.confirm
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportInitializeCard.sendMail.next")) + " ")])];
      },
      proxy: true
    }])
  }, [_c('partner-report-initialize-card', {
    ref: "initializeCard",
    attrs: {
      "initReport": _vm.initReport
    },
    on: {
      "update": function update(v) {
        return _vm.url = v;
      }
    }
  }), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "8",
      "justify": "center",
      "align-self": "center"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.url,
      "label": _vm.$t('components.partner.PartnerReportInitializeCard.result'),
      "readonly": "",
      "outlined": "",
      "hide-details": "",
      "append-icon": "mdi-content-copy"
    },
    on: {
      "click:append": _vm.copyLinkToClipboard,
      "click": _vm.copyLinkToClipboard
    }
  }), _vm.whatsappLink ? _c('v-btn', {
    staticClass: "mt-4",
    attrs: {
      "color": "rgba(40,206,82)",
      "dark": "",
      "elevation": "0",
      "href": _vm.whatsappLink
    },
    on: {
      "click": _vm.openWhatsapp
    }
  }, [_c('v-icon', [_vm._v("mdi-whatsapp")]), _vm._v(" Chat on WhatsApp ")], 1) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4",
      "justify": "center",
      "align-self": "center"
    }
  }, [_c('qrcode-vue', {
    ref: "qrcode",
    staticStyle: {
      "text-align": "end"
    },
    attrs: {
      "value": _vm.url,
      "size": "256",
      "level": "L",
      "data-test-qrcode": ""
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }