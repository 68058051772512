var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "margin": 0,
      "title": _vm.$t('components.partner.PartnerReportInitializeCard.empty.success')
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "data-test-close-success": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('close');
            }
          }
        }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('v-card-text', _vm._l(_vm.tickets, function (ticket) {
    return _c('v-card', {
      key: 'PartnerReportInitializeDialogEmptySuccess' + ticket.number,
      staticClass: "ma-1",
      attrs: {
        "outlined": ""
      }
    }, [_c('refs-ticket', {
      attrs: {
        "item": ticket
      },
      on: {
        "openDetail": _vm.openDetail
      }
    })], 1);
  }), 1), _c('v-card-actions', [_c('v-spacer'), _c('Can', {
    attrs: {
      "I": _vm.ActionEnum.CREATE,
      "a": _vm.ResourceEnum.TICKET,
      "field": _vm.partnerId
    }
  }, [_c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "text": "",
      "color": "info",
      "outlined": "",
      "data-test-to-create-ticket": ""
    },
    on: {
      "click": _vm.createTicket
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportInitializeCard.empty.createTicket")) + " ")]), _c('v-spacer')], 1)], 1), _c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "color": "info",
      "data-test-to-report": ""
    },
    on: {
      "click": _vm.openReport
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportInitializeCard.empty.toReport")) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }